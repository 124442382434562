import * as React from "react";
import Layout from "../components/Layout";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import processString from "react-process-string";

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p>{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,

    "embedded-asset-block": (node) => {
      const { gatsbyImageData } = node.data.target;
      const image = getImage(gatsbyImageData);

      console.log(node);
      if (!gatsbyImageData) {
        // asset is not an image
        return null;
      }
      return (
        <figure className="articleInlineImageWrapper">
          <GatsbyImage
            className="articleInlineImage"
            placeholder="blurred"
            image={image}
          />
          {node.data.target.title ? (
            <figcaption>
              {node.data.target ? node.data.target.title : ""}
            </figcaption>
          ) : (
            ""
          )}
        </figure>
      );
    },
  },
  renderText: (text) => {
    let StringProcessConfig = [
      {
        regex: /\[\[(.*?)\]\]/,
        fn: (key, result) => (
          <a
            href={`#body-${result[1]}`}
            id={`footnote-${result[1]}`}
            className="footnoteReference"
            key={`body` + result}
          >
            {result[1]}
          </a>
        ),
      },
      {
        regex: /\(\((.*?)\)\)/,
        fn: (key, result) => (
          <a
            href={`#footnote-${result[1]}`}
            id={`body-${result[1]}`}
            className="footnoteNumber"
            key={`footnote` + result[1]}
          >
            {result[1]}
          </a>
        ),
      },
    ];

    let textToRender = processString(StringProcessConfig)(text);

    return textToRender;
  },
};

const Article = ({ data }) => {
  const {
    heroImage,
    body,
    title,
    type,
    introduction,
    datePublished,
    author,
    footnotes,
    showNumericalDate,
    heroVideo,
    intro,
  } = data.contentfulArticle;

  let datePublishedEnglish = new Date(datePublished).toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  if (showNumericalDate) {
    datePublishedEnglish = new Date(datePublished).toLocaleString("default", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  } else {
  }

  return (
    <Layout type={type}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} • System of Systems</title>
      </Helmet>
      <div className="_4across topOfPage">
        <div className="articleWrapper">
          {type ? (
            <p
              className={
                type === "Essay" || type === "Exhibition"
                  ? `whiteText typewriter`
                  : `typewriter`
              }
            >
              {type}
            </p>
          ) : (
            ""
          )}
          <h1
            className={
              type === "Essay" || type === "Exhibition"
                ? `whiteText largeTitle`
                : `largeTitle`
            }
          >
            {title}
          </h1>
          {heroVideo && (
            <div className="articleVideoWrapper">
              <iframe
                className="articleVideo"
                src={heroVideo}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          )}
          {heroImage && !heroVideo && (
            <div className="heroImageWrapper">
              <GatsbyImage
                placeholder="blurred"
                className="heroImage"
                image={getImage(heroImage)}
              />
              {heroImage.title ? (
                <figcaption className="heroCaption">
                  {heroImage.title}
                </figcaption>
              ) : (
                ""
              )}
            </div>
          )}

          {intro && (
            <div className="introduction">{renderRichText(intro, options)}</div>
          )}
          <div className="metaData">
            <p>{datePublished && datePublishedEnglish}</p>
            <p> {author && author.name}</p>
          </div>

          <div className="articleBody">
            {body && renderRichText(body, options)}
          </div>
          <div className="footnotes">
            {footnotes && renderRichText(footnotes, options)}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    contentfulArticle(id: { eq: $id }) {
      heroVideo
      title
      type
      datePublished
      showNumericalDate
      author {
        name
      }
      heroImage {
        title
        gatsbyImageData(width: 1200)
      }
      footnotes {
        raw
      }
      intro {
        raw
      }
      body {
        raw
        references {
          ... on Node {
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData
              title
            }
          }
        }
      }
    }
  }
`;

export default Article;
